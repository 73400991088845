@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Zen+Tokyo+Zoo&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Zen+Tokyo+Zoo&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    /* capa oscura */ url("../public/assets/fondo-pagina.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

body {
  margin: 0;
  /* font-family: "Zen Tokyo Zoo", system-ui; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Personaliza el contenedor de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento vertical */
  height: 4px; /* Altura de la barra de desplazamiento horizontal */
}

/* Personaliza el fondo de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background-color: #1b1b1b; /* Color de fondo */
}

/* Personaliza el "thumb", la parte móvil de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background-color: #635d5a; /* Color de la barra deslizante */
}
